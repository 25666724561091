import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';

import ALink from '../utils/alink';

import { cartActions } from '../store/cart';
import { modalActions } from '../utils/modal';
import { wishlistActions } from '../store/wishlistReducer';

import { toDecimal } from '../utils';
import { useCompanyConfig } from '..//..//fetch-data/config'
function ProductTwo(props) {
    const { product, adClass = 'text-center', toggleWishlist, wishlist, addToCart, openQuickview, collectionSlug = '',collectionData } = props;
    const { data, isLoading, error, refetch } = useCompanyConfig();
    let isWishlisted;
    if(!data){
        return null;
    }
    return (
        <div className={`product text-left ${adClass}`}>
            <figure className="product-media">
                <ALink href={`${collectionSlug.length > 0 ? `/collections/${collectionSlug}` : ''}/products/${product.slug}`}>
                    <LazyLoadImage
                        alt="product"
                        src={product.pictures[0].url}
                        threshold={500}
                        effect="opacity"
                        width="300"
                        height="338"
                    />

                    {
                        product.pictures.length >= 2 ?
                            <LazyLoadImage
                                alt="product"
                                src={product.pictures[1].url}
                                threshold={500}
                                width="300"
                                height="338"
                                effect="opacity"
                                wrapperClassName="product-image-hover"
                            />
                            : ""
                    }
                </ALink>

                <div className="product-label-group">
                    {product.is_new ? <label className="product-label label-new">New</label> : ''}
                    {product.is_top ? <label className="product-label label-top">Top</label> : ''}
                    {
                        product.discount > 0 ?
                            product.variants.length === 0 ?
                                <label className="product-label label-sale">{product.discount}% OFF</label>
                                : <label className="product-label label-sale">Sale</label>
                            : ''
                    }
                </div>



                <div className="product-action">
                    <ALink href={collectionSlug.length > 0 ? `/collections/${collectionSlug}/products/${product.slug}` : `/products/${product.slug}`} className="btn-product btn-quickview" title="Quick View" >View</ALink>
                </div>
            </figure>

            <div className="product-details">
                <div className="product-cat">
                    {
                        <ALink >{collectionData.collectionName}</ALink>
                    }
                </div>
                <h3 className="product-name">
                    <ALink href={collectionSlug.length > 0 ? `/collections/${collectionSlug}/products/${product.slug}` : `/products/${product.slug}`}>
                        {product.name}
                    </ALink>
                </h3>
                <div className="product-name">
                    {/* {
                        product.price[0] !== product.price[1] ?
                            product.variants.length === 0 || (product.variants.length > 0 && !product.variants[0].price) ?
                                <>
                                    <ins className="new-price">${toDecimal(product.price[0])}</ins>
                                    <del className="old-price">${toDecimal(product.price[1])}</del>
                                </>
                                :
                                < del className="new-price">${toDecimal(product.price[0])} – ${toDecimal(product.price[1])}</del>
                            : <ins className="new-price">${toDecimal(product.price[0])}</ins>
                    } */}
                    <div className="new-price">{data.currency}{toDecimal(product.onePrice)}</div>
                </div>

                {/* <div className="ratings-container">
                    <div className="ratings-full">
                        <span className="ratings" style={{ width: 20 * product.ratings + '%' }}></span>
                        <span className="tooltiptext tooltip-top">{toDecimal(product.ratings)}</span>
                    </div>

                    <ALink href={`/products/${product.slug}`} className="rating-reviews">( {product.reviews} reviews )</ALink>
                </div> */}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        wishlist: state.wishlist.data ? state.wishlist.data : []
    }
}

export default connect(mapStateToProps, { toggleWishlist: wishlistActions.toggleWishlist, addToCart: cartActions.addToCart, ...modalActions })(ProductTwo);