import React, { useState, useEffect } from 'react';
import Reveal from 'react-awesome-reveal';
import OwlCarousel from '../owl-carousel/owl-carousel';
import ProductTwo from './productTwo';
import { featuredSlider } from '../owl-carousel/data/carousel';
import ALink from '../utils/alink';
import { useCompanyConfig } from '../../fetch-data/config';
import Spinner from '../utils/spinner/full-page-spinner';

function ProductGridView(props) {
    const { collectionSlug } = props;
    const { data, isLoading, error } = useCompanyConfig();
    const [collectionList, setCollectionList] = useState([]);
    const [products, setProducts] = useState([]);
    const [isProductLoading, setProductLoading] = useState(false);

    useEffect(() => {
        if (!isLoading && data) {
            console.log('Data from Prod ==>', data);
            const collection = data.collection || [];
            setCollectionList(collection);
            const filteredProducts = data.products.filter(
                (product) => product.collection.collectionSlug === collectionSlug.collectionSlug
            );
            setProducts(filteredProducts);
        }
    }, [data, isLoading, collectionSlug]);

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <div>
            {collectionList.length > 0 &&
                collectionList.map((oneCollection) => (
                    <section className="pt-7 pb-1" key={oneCollection.collectionSlug}>
                        <ALink href="#">
                            <h2 className="title title-simple ls-m">{oneCollection.collectionName}</h2>
                        </ALink>

                        <Reveal triggerOnce>
                            {isProductLoading ? (
                                <OwlCarousel adClass="owl-theme owl-nav-full" options={featuredSlider}>
                                    {[1].map((item) => (
                                        <div className="product-loading-overlay" key={'featured-skel-' + item}></div>
                                    ))}
                                </OwlCarousel>
                            ) : (
                                <OwlCarousel adClass="owl-theme owl-nav-full" options={featuredSlider}>
                                    {products.map((item, index) => (
                                        <ProductTwo
                                            adClass="text-left"
                                            product={item}
                                            key={`featured-product-${index}`}
                                            collectionSlug={oneCollection.collectionSlug}
                                            collectionData={oneCollection}
                                        />
                                    ))}
                                </OwlCarousel>
                            )}
                        </Reveal>
                    </section>
                ))}
        </div>
    );
}

export default React.memo(ProductGridView);
