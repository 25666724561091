import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '../utils/alink';
import { useCompanyConfig } from '../../fetch-data/config';
import alasql from 'alasql';

function HomeSearch(props) {
    const { SearchBarForMobile = false } = props;
    const { data, isLoading, error } = useCompanyConfig();
    const router = useLocation();
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    useEffect(() => {
        const searchElement = document.querySelector('.header-search.show-results');
        if (searchElement) searchElement.classList.remove('show-results');
    }, [router.pathname]);

    const onSearchClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.parentNode.classList.toggle('show');
    };

    const onBodyClick = (e) => {
        if (e.target.closest('.header-search')) {
            const headerSearch = e.target.closest('.header-search');
            if (!headerSearch.classList.contains('show-results')) {
                headerSearch.classList.add('show-results');
            }
            return;
        }
        const showElement = document.querySelector('.header-search.show');
        if (showElement) showElement.classList.remove('show');
        const resultsElement = document.querySelector('.header-search.show-results');
        if (resultsElement) resultsElement.classList.remove('show-results');
    };

    useEffect(() => {
        document.body.addEventListener('click', onBodyClick);
        return () => {
            document.body.removeEventListener('click', onBodyClick);
        };
    }, []);

    const matchEmphasize = (name, query) => {
        const parts = name.split(new RegExp(`(${query})`, 'gi'));
        return parts.map((part, i) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <strong key={i}>{part}</strong>
            ) : (
                part
            )
        );
    };
    /// use JS Logic to filter product by product and collection name
    // const onSearchChange = (event) => {
    //     const query = event.target.value;
    //     setSearch(query);

    //     if (query.length > 2) {
    //         const filteredResults = data?.products?.filter((product) => {
    //             const productNameMatch = product.name.toLowerCase().includes(query.toLowerCase());
    //             const collectionMatch = product.collection.collectionName?.toLowerCase().includes(query.toLowerCase());
    //             return productNameMatch || collectionMatch;
    //         });
    //         setSearchResult(filteredResults || []);
    //     } else {
    //         setSearchResult([]);
    //     }
    // };
    /// ALASQL version
    const onSearchChange = (event) => {
        const query = event.target.value;
        setSearch(query);
        if (query.length > 2) {
            const sql = `SELECT * FROM ? WHERE LOWER(name) LIKE '%${query.toLowerCase()}%' OR LOWER(collection->collectionName) LIKE '%${query.toLowerCase()}%'`;
            const filteredResults = alasql(sql, [data.products]);
            setSearchResult(filteredResults || []);
        } else {
            setSearchResult([]);
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading data</div>;

    return (
        <div className={`header-search hs-simple ${SearchBarForMobile ? 'mr-0' : ''}`}>
            <a href="#" className="search-toggle" role="button" onClick={onSearchClick}>
                <i className="icon-search-3"></i>
            </a>
            <form action="#" method="get" className="input-wrapper">
                <input
                    type="text"
                    className="form-control"
                    name="search"
                    autoComplete="off"
                    value={search}
                    onChange={onSearchChange}
                    placeholder="Search..."
                    required
                />

                <button className="btn btn-search" type="submit">
                    <i className="d-icon-search"></i>
                </button>

                <div className={`live-search-list bg-white scrollable ${searchResult.length > 0 ? 'show' : ''}`}>
                    {search.length > 2 ? (
                        searchResult.length > 0 ? (
                            searchResult.map((product, index) => (
                                <ALink
                                    href={`/collections/${product.collection.collectionSlug}/products/${product.slug}`}
                                    className="autocomplete-suggestion"
                                    key={`search-result-${index}`}
                                >
                                    <LazyLoadImage
                                        src={product.pictures[0]?.url || ''}
                                        width={40}
                                        height={40}
                                        alt="product"
                                    />
                                    <div className="search-name">
                                        {matchEmphasize(product.name, search)}
                                    </div>
                                    <span className="search-price">
                                        <span className="new-price mr-1">{data.currency}{product.onePrice}</span>
                                    </span>
                                </ALink>
                            ))
                        ) : (
                            <div className="pl-2">No products found</div>
                        )
                    ) : (
                        search.length >= 1 && (
                            <>
                                {[1, 2, 3, 4].map((_, index) => (
                                    <div className="autocomplete-suggestion" key={`loading-placeholder-${index}`}>
                                        <LazyLoadImage
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjQTyBhMZCPlcC5iqPn_Os3ZeUQ0S3171eGA&s"
                                            width={40}
                                            height={40}
                                            alt="placeholder"
                                        />
                                        <div className="search-name loading"></div>
                                        <span className="search-price">
                                            <span
                                                className="new-price mr-1"
                                                style={{
                                                    display: 'flex',
                                                    height: '18px',
                                                    width: '30vw',
                                                    backgroundColor: '#d8d9dd',
                                                    marginBottom: '1%',
                                                }}
                                            ></span>
                                            <span
                                                className="old-price"
                                                style={{
                                                    display: 'flex',
                                                    height: '18px',
                                                    width: '15vw',
                                                    backgroundColor: '#d8d9dd',
                                                    marginBottom: '1%',
                                                }}
                                            ></span>
                                        </span>
                                    </div>
                                ))}
                            </>
                        )
                    )}
                </div>
            </form>
        </div>
    );
}

export default React.memo(HomeSearch);
