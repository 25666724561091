import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ALink from "../utils/alink";
import Quantity from "../utils/quantity/Quantity";
import { cartActions } from "../store/cart";
import { toDecimal, getTotalPrice } from "../utils";
import { Helmet } from "react-helmet";
import { useCompanyConfig } from "../../fetch-data/config";
import Spinner from "../utils/spinner/full-page-spinner";
function Cart(props) {
  const { cartList, removeFromCart, updateCart } = props;
  const [cartItems, setCartItems] = useState([]);
  const { data, isLoading, error, refetch } = useCompanyConfig();
  useEffect(() => {
    setCartItems([...cartList]);
  }, [cartList]);
  const onChangeQty = (productInstanceId, qty) => {
    const updatedItems = cartItems.map((item) =>
      item.productInstanceId === productInstanceId ? { ...item, qty: qty } : item
    );
    setCartItems(updatedItems);
    updateCart(updatedItems);
  };
  if(isLoading){
    return <Spinner />;
  }
  return (
    <>
      <Helmet>
        <title>{data.companyName} | Cart</title>
        <link rel="icon" type="image/png" href={data.favIcon} />
        <meta name="description" content={data.siteDescription} />
      </Helmet>
      <div className="main cart">
        <div className="page-content pt-7  pb-10">
          <div className="container mt-7 mb-2">
            <div className="row">
              {cartItems.length > 0 ? (
                <>
                  <div className="col-lg-8 col-md-12 pr-lg-4">
                    <table className="shop-table cart-table">
                      <thead>
                        <tr>
                          <th>
                            <span>Product</span>
                          </th>
                          <th></th>
                          <th>
                            <span>Price</span>
                          </th>
                          <th>
                            <span>quantity</span>
                          </th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item) => (
                          <tr key={"cart" + item.name}>
                            <td className="product-thumbnail">
                              <figure>
                                <ALink href={`/collections/${item.collection.collectionSlug}/products/${item.slug}`}>
                                  <img
                                    src={item.pictures[0].url}
                                    width="100"
                                    height="100"
                                    alt="product"
                                  />
                                </ALink>
                              </figure>
                            </td>
                            <td colSpan="1">
                              <div className="product-name-section">
                                <ALink href={`/collections/${item.collection.collectionSlug}/products/${item.slug}`}>
                                  <p className="mb-2 font-weight-bolder" >{item.name}</p>
                                </ALink>
                                {item.formFields.length>0? item.formFields.map((singleField) => (
                                  <p className="mt-0 mb-0 overflow-ellipse" >{singleField.label}: {singleField.value}</p>
                                )):null}
                              </div>
                            </td>
                            <td className="product-subtotal">
                              <span className="amount">
                                {data.currency}{toDecimal(item.price)}
                              </span>
                            </td>

                            <td className="product-quantity">
                              <Quantity
                                qty={item.qty}
                                max={item.stock}
                                onChangeQty={(qty) => onChangeQty(item.productInstanceId, qty)}
                              />
                            </td>
                            <td className="product-price">
                              <span className="amount">
                                {data.currency}{toDecimal(item.price * item.qty)}
                              </span>
                            </td>
                            <td className="product-close">
                              <ALink
                                href="#"
                                className="product-remove"
                                title="Remove this product"
                                onClick={() => removeFromCart(item)}
                              >
                                <i className="fas fa-times"></i>
                              </ALink>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <aside className="col-lg-4 sticky-sidebar-wrapper">
                    <div
                      className="sticky-sidebar"
                      data-sticky-options="{'bottom': 20}"
                    >
                      <div className="summary mb-4">
                        <h3 className="summary-title text-left">Cart Totals</h3>
                        <table className="shipping">
                          <tbody>
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Subtotal</h4>
                              </td>
                              <td>
                                <p className="summary-subtotal-price">
                                  {data.currency}{toDecimal(getTotalPrice(cartItems))}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="total">
                          <tbody>
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Total</h4>
                              </td>
                              <td>
                                <p className="summary-total-price ls-s">
                                  {data.currency}{toDecimal(getTotalPrice(cartItems))}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className=" cart-actions  pt-4 justify-content-center mb-2 border-bottom">
                            <ALink href="/" className="btn btn-dark btn-link ">Continue Shopping</ALink>
                        </div>
                        <ALink
                          href="checkout"
                          className="btn btn-dark btn-rounded btn-checkout"
                        >
                          Proceed to checkout
                        </ALink>

                      </div>
                    </div>
                  </aside>
                </>
              ) : (
                <div className="empty-cart text-center">
                  <p>Your cart is currently empty.</p>
                  <i className="cart-empty d-icon-bag"></i>
                  <p className="return-to-shop mb-0">
                    <ALink
                      className="button wc-backward btn btn-dark btn-md"
                      href="/"
                    >
                      Return to shop
                    </ALink>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    cartList: state.cart.data ? state.cart.data : [],
  };
}

export default connect(mapStateToProps, {
  removeFromCart: cartActions.removeFromCart,
  updateCart: cartActions.updateCart,
})(React.memo(Cart));
