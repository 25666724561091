import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCompanyConfig } from '..//..//fetch-data/config'
import SingleColumnTabView from './Tabs/SingleColumnTabView';
import ThreeColumnImageView from './Tabs/ThreeColumnImageTabView'
import ThreeColumnTabView from './Tabs/ThreeColumnTabView';
import FourColumnImageTabView from './Tabs/FourColumnImageTabView';
import SinglePropertyTabView from './Tabs/SinglePropertyTabView';
function MainMenu() {
    const Pathname = useLocation().pathname;
    const { data, isLoading, error, refetch } = useCompanyConfig();
    if (isLoading) {
        return null
    }
    return (
        <nav className="main-nav">
            <ul className="menu menu-active-underline">
                {
                    data ? data.tabs.map((HeaderMenuItem) => (
                        HeaderMenuItem.tabType === 'SinglePropertyTabView' && HeaderMenuItem.isActive ?
                            <SinglePropertyTabView
                                Pathname={Pathname}
                                HeaderMenuItem={HeaderMenuItem}
                            />
                            :
                            HeaderMenuItem.tabType === 'SingleColumnTabView' && HeaderMenuItem.isActive ?
                                <SingleColumnTabView
                                    Pathname={Pathname}
                                    HeaderMenuItem={HeaderMenuItem}
                                />
                                :
                                HeaderMenuItem.tabType === 'ThreeColumnImageView' && HeaderMenuItem.isActive ?
                                    <ThreeColumnImageView
                                        Pathname={Pathname}
                                        HeaderMenuItem={HeaderMenuItem}
                                    />
                                    : HeaderMenuItem.tabType === 'ThreeColumnTabView' && HeaderMenuItem.isActive ?
                                        <ThreeColumnTabView
                                            Pathname={Pathname}
                                            HeaderMenuItem={HeaderMenuItem}
                                        />
                                        : HeaderMenuItem.tabType === 'FourColumnImageTabView' && HeaderMenuItem.isActive ?
                                            <FourColumnImageTabView
                                                Pathname={Pathname}
                                                HeaderMenuItem={HeaderMenuItem}
                                            />
                                            : ''
                    )) : ''
                }
            </ul>
        </nav >
    )
}

export default MainMenu;