import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/store';
////////////////  import CSS ///////////////////////////////////////
import './index.css';
import './public/sass/style.scss'
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-image-lightbox/style.css';
import './/public/vendor/riode-fonts/riode-fonts.css'
/////////////////////////////////////////////////////////////////////

////////////////  import compo ///////////////////////////////////////
import Layout from './components/layout';
import Home from './components/home/home';
import ProductHome from './components/product-view/product-home';
import Cart from './components/cart/cart';
import Error404 from './pages/Error404';
import Checkout from './components/checkout/checkout';
import { CompanyConfigProvider } from './fetch-data/config';
import ContactUs from './components/contact-us/contact-us';
import Order from './components/order/order';
/////////////////////////////////////////////////////////////////////
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <CompanyConfigProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout children={<Home />} />} />
            <Route path="/pages/cart" element={<Layout children={<Cart />} />} />
            <Route path="/pages/checkout" element={<Layout children={<Checkout />} />} />
            <Route path="/pages/contact-us" element={<Layout children={<ContactUs />} />} />
            <Route path="pages/order?/:session_id" element={<Layout children={<Order />} />} />
            <Route path="*" element={<Layout children={<Error404 />} />} />
            <Route path="collections/:collection-slug/products/:productSlug" element={<Layout children={<ProductHome />} />} />
          </Routes>
        </Router>
      </CompanyConfigProvider>
    </Provider>
  </QueryClientProvider>
);
reportWebVitals();
