import React from "react";
import ALink from "../utils/alink";
import { useCompanyConfig } from "../../fetch-data/config";

export default function Footer() {
    const { data, isLoading, error, refetch } = useCompanyConfig();
    if(isLoading){
        return <></>
    }
    return (
        <footer className="footer hide-on-print">
            <div className="container">
                <div className="footer-middle">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="widget widget-about">
                                <ALink href="/" className="logo-footer mb-4">
                                    <img
                                        src={data.footer.src}
                                        alt={data.footer.altText}
                                        width={data.footer.width}
                                        height={data.footer.height}
                                    />
                                </ALink>
                                <div className="widget-body">
                                    {data.address.line1}<br /><p>{data.address.line2}<br />{data.address.line3}<br />{data.address.line4}<br /></p>
                                    <ALink href={`mailto:${data.email}`}>{data.email}</ALink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-left">
                    </div>
                    <div className="footer-center">
                        <p className="copyright">
                            {data.copyright}
                        </p>
                    </div>
                    <div className="footer-right">
                        <div className="social-links">
                            <ALink href={data.socialMediaLinks.facebook} className="social-link social-facebook fab fa-facebook-f"></ALink>
                            <ALink href={data.socialMediaLinks.twitter} className="social-link social-twitter fab fa-twitter"></ALink>
                            <ALink href={data.socialMediaLinks.linkedin} className="social-link social-linkedin fab fa-linkedin-in"></ALink>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
