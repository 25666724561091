import React, { useEffect, useLayoutEffect } from 'react'
import Header from './header/header'
import Footer from './footer/footer';
import Alink from './utils/alink'
import MobileMenu from './menu/mobile-menu';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import { useCompanyConfig } from '..//fetch-data/config'
function Layout({ children }) {
    const router = useLocation();
    const { data, isLoading, error, refetch } = useCompanyConfig();
    useLayoutEffect(() => {
        document.querySelector('body').classList.remove('loaded');
    }, [router.pathname])
    useEffect(() => {

        let bodyClasses = [...document.querySelector("body").classList];
        for (let i = 0; i < bodyClasses.length; i++) {
            document.querySelector('body').classList.remove(bodyClasses[i]);
        }

        setTimeout(() => {
            document.querySelector('body').classList.add('loaded');
        }, 50);
    }, [router.pathname])
   if(!data){
    return null
   }
    return (
        <>
            <div className="page-wrapper">
                <Header />
                {children}
                <Footer/>
            </div>
            <MobileMenu data={data}/>
            <Alink id="scroll-top" href="#" title="Top" role="button" className="scroll-top"><i className="d-icon-arrow-up"></i></Alink>
            <ToastContainer
                autoClose={3000}
                duration={300}
                newestOnTo={true}
                className="toast-container"
                position="bottom-left"
                closeButton={false}
                hideProgressBar={true}
                newestOnTop={true}
            />
        </>
    )
}
export default React.memo(Layout)
