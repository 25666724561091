import React, { Suspense} from 'react'
import ALink from '../utils/alink';
import MainMenu from '../menu/main-menu';
import CartMenu from '../cart/cart-menu';
import { useCompanyConfig } from '../../fetch-data/config';
import HomeSearch from '..//search/home-searchbox'
function Header() {
  const { data, isLoading, error, refetch } = useCompanyConfig();
  const showMobileMenu = () => {
    document.querySelector('body').classList.add('mmenu-active');
  }
  if(isLoading){
    return <></>
  }
  return (
    <header className="header hide-on-print">
      <div className="header-middle sticky-header fix-top sticky-content">
        <div className="container">
          <div className="header-left">
            <ALink href="#" className="mobile-menu-toggle" onClick={showMobileMenu}>
              <i className="d-icon-bars2"></i>
            </ALink>
            <ALink href="/" className="logo">
              <Suspense fallback={<div>Loading...</div>}>
              <img src={data.header.src} alt={data.header.altText} width={data.header.width} height={data.header.height} />
              </Suspense>
            </ALink>
            <HomeSearch/>
          </div>
          <div className="header-right">
            <CartMenu />
          </div>
        </div>
      </div>
      <div className="header-bottom d-lg-show">
        <div className="container">
          <div className="header-left">
            <MainMenu />

          </div>
        </div>
      </div>

    </header >
  );
}

export default Header
