import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getTotalPrice, getCartCount, toDecimal } from "..//utils/index";
import ALink from "../utils/alink";
import { cartActions } from "..//store/cart";
import { useCompanyConfig } from '..//..//fetch-data/config'
function CartMenu(props) {
  const { cartList, removeFromCart } = props;
  const { data, isLoading, error, refetch } = useCompanyConfig();
  const router = useLocation();
  useEffect(() => {
    hideCartMenu();
  }, [router.asPath]);

  const showCartMenu = (e) => {
    e.preventDefault();
    e.currentTarget.closest(".cart-dropdown").classList.add("opened");
  };

  const hideCartMenu = () => {
    document.querySelector(".cart-dropdown").classList.remove("opened");
  };

  const removeCart = (item) => {
    removeFromCart(item);
  };
  if(!data){
    return null;
  }
  return (
    <div className="dropdown cart-dropdown type2 cart-offcanvas mr-0 mr-lg-2">
      <a
        href="/"
        className="cart-toggle label-block link"
        onClick={showCartMenu}
      >
        <div className="cart-label d-lg-show">
          <span className="cart-name">Shopping Cart:</span>
          <span className="cart-price">
            {data.currency}
            {toDecimal(getTotalPrice(cartList))}
          </span>
        </div>
        <i className="d-icon-bag">
          <span className="cart-count">{getCartCount(cartList)}</span>
        </i>
      </a>
      <div className="cart-overlay" onClick={hideCartMenu}></div>
      <div className="dropdown-box">
        <div className="cart-header">
          <h4 className="cart-title">Shopping Cart</h4>
          <ALink
            href="#"
            className="btn btn-dark btn-link btn-icon-right btn-close"
            onClick={hideCartMenu}
          >
            close<i className="d-icon-arrow-right"></i>
            <span className="sr-only">Cart</span>
          </ALink>
        </div>
        {cartList.length > 0 ? (
          <>
            <div className="products scrollable">
              {cartList.map((item, index) => (
                <div
                  className="product product-cart"
                  key={"cart-menu-product-" + index}
                >
                  <figure className="product-media pure-media col-lg-3">
                  <ALink href={`/collections/${item.collection.collectionSlug}/products/${item.slug}`}>
                      <img
                        src={item.pictures[0].url}
                        alt="product"
                        width="80"
                        height="88"
                      />
                    </ALink>
                    <button
                      className="btn btn-link btn-close"
                      onClick={() => {
                        removeCart(item);
                      }}
                    >
                      <i className="fas fa-times"></i>
                      <span className="sr-only">Close</span>
                    </button>
                  </figure>
                  <div className="product-detail col-lg-7 overflow-ellipse-for-productName" >
                    <div style={{ width: "99%" }}>
                      <p className="mb-2 overflow-ellipse-for-productName" >{item.name}</p>
                      {
                        item.formFields.map((singleField) => (
                          <p className="cart-menu-product-details overflow-ellipse">{singleField.label}: {singleField.value}</p>
                        ))
                      }


                    </div>
                    <div className="price-box">
                      <span className="product-quantity">{item.qty}</span>
                      <span className="product-price">
                        {data.currency}
                        {toDecimal(item.price)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="cart-total">
              <label>Subtotal:</label>
              <span className="price">
                {data.currency}
                {toDecimal(getTotalPrice(cartList))}
              </span>
            </div>

            <div className="cart-action">
              <ALink
                className="btn btn-dark btn-link"
                onClick={hideCartMenu}
              >
                Continue Shopping
              </ALink>
              <ALink
                href="/pages/cart"
                className="btn btn-dark"
                onClick={hideCartMenu}
              >
                <span>Checkout</span>
              </ALink>
            </div>
          </>
        ) : (
          <p className="mt-4 text-center font-weight-semi-bold ls-normal text-body">
            No products in the cart.
          </p>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cartList: state.cart.data,
  };
}

export default connect(mapStateToProps, {
  removeFromCart: cartActions.removeFromCart,
})(CartMenu);
