import React, { useState, useEffect, memo } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import GenerateInputFields from "../dynamic-form/generate-input-fields";
import MediaOne from "../utils/product-media/MediaOneForGental";
import { useParams } from "react-router-dom";
import Quantity from "../utils/quantity/Quantity";
import { cartActions } from "../store/cart";
import ALink from "../utils/alink";
import { fadeIn } from "../utils/keyFrames";
import Reveal from "react-awesome-reveal";
import Spinner from "../utils/spinner/full-page-spinner";
import { useCompanyConfig } from "../../fetch-data/config";

function ProductHome(props) {
    const { data, isLoading, error } = useCompanyConfig();
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState(null);
    const { productSlug } = useParams();
    useEffect(() => {
        if (data && !isLoading) {
            const filteredProduct = data.products.find(
                (prod) => prod.slug === productSlug
            );
            setProduct(filteredProduct || null);
        }
    }, [data, isLoading, productSlug]);

    // Add to Cart Handler
    const handleAddToCartSubmit = (event) => {
        event.preventDefault();
        const formElement = document.getElementById("productForm");
        const uniqueProductInstanceId = createUniqueNameForProduct(formElement);
        const formFieldsWithValues = populateFormFieldsWithValues(formElement);

        props.addToCart({
            ...product,
            productInstanceId: uniqueProductInstanceId,
            qty: quantity,
            price: product.onePrice,
            formFields: formFieldsWithValues,
        });

        formElement.reset();
    };

    const populateFormFieldsWithValues = (formElement) => {
        const formData = new FormData(formElement);
        const fieldValues = Object.fromEntries(formData.entries());
        return product.formFields.map((field) => ({
            ...field,
            value: fieldValues[field.name] || "",
        }));
    };

    const createUniqueNameForProduct = (formElement) => {
        const formData = new FormData(formElement);
        return JSON.stringify({
            productName: product.name,
            formDetails: Object.fromEntries(formData.entries()),
        });
    };

    const changeQty = (qty) => setQuantity(qty);

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <div>Error loading product data</div>;
    }

    if (!product) {
        return <p className="container mt-2">Product Not Found</p>;
    }

    return (
        <>
            <Helmet>
                <title>Product | {product.name}</title>
                <link rel="icon" type="image/png" href={data.favIcon} />
                <meta name="description" content={data.siteDescription} />
            </Helmet>
            <div className="page-content mb-10 pb-6">
                <div className="container vertical">
                    <div className="product product-single row mb-2">
                        {/* Media Section */}
                        <div className="col-md-6 mt-4 sticky-sidebar-wrapper">
                            <MediaOne product={[product]} />
                        </div>

                        {/* Product Details Section */}
                        <div className="col-md-6">
                            <nav className="breadcrumb-nav">
                                <div className="container pl-1">
                                    <ul className="breadcrumb">
                                        <li>
                                            <ALink href="/">
                                                <i className="d-icon-home"></i>
                                            </ALink>
                                        </li>
                                        <li>{product.name}</li>
                                    </ul>
                                </div>
                            </nav>
                            <form
                                className="pl-lg-2"
                                onSubmit={handleAddToCartSubmit}
                                id="productForm"
                            >
                                <main className="main contact-us">
                                    <div className="page-content pt-3">
                                        <Reveal
                                            keyframes={fadeIn}
                                            delay="50"
                                            duration="1000"
                                            triggerOnce
                                        >
                                            <section className="contact-section">
                                                <div className="w-100">
                                                    <h4 className="ls-m font-weight-bold">
                                                        {product.name}
                                                    </h4>
                                                    <p>{product.short_description}</p>
                                                    <h5>{data.currency}{product.onePrice}</h5>
                                                    <GenerateInputFields
                                                        formFields={product.formFields}
                                                    />
                                                </div>
                                            </section>
                                        </Reveal>
                                    </div>
                                </main>
                                <div className="product-form product-qty pb-0">
                                    <div className="product-form-group">
                                        <Quantity
                                            qty={quantity}
                                            max={product.stock}
                                            product={product}
                                            onChangeQty={changeQty}
                                        />
                                        <button
                                            type="submit"
                                            className="btn-product btn-cart text-normal ls-normal font-weight-semi-bold"
                                        >
                                            <i className="d-icon-bag"></i>
                                            Add to Cart
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        wishlist: state.wishlist.data || [],
    };
}

export default connect(mapStateToProps, {
    addToCart: cartActions.addToCart,
})(memo(ProductHome));
